import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomConnectButton from "./components/CustomConnectButton";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Image } from "./components/Images";
import { TransactionStateToast } from "./components/TransactionStateToast";
import SphereLogo from "./assets/SphereIconLogoGreen.png";
import { FaucetButton } from "./components/FaucetButton";
import { TwitterButton } from "./components/TwitterButton";
import { useAccount } from "wagmi";
import { sphereone } from "./utils/wagmiConfig";
import { useFaucetContext } from "./contexts/FaucetContext";
import { QueueStatus } from "./utils/types";
import { useState, useEffect } from "react";

export default function App() {
  const { isConnected, chain } = useAccount();
  const { twitterQueueDocItem, queueDocItem } = useFaucetContext();

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (
      queueDocItem?.status === QueueStatus.QUEUED ||
      twitterQueueDocItem?.status === QueueStatus.QUEUED
    ) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [queueDocItem, twitterQueueDocItem]);

  return (
    <div className="flex flex-col lg:px-8 sm:px-20 px-4 min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white font-body">
      <Header />

      <main className="flex-1 flex items-center justify-center py-8 sm:py-12">
        <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          <div className=" flex flex-col lg:flex-row items-center sm:items-stretch justify-between gap-8 lg:gap-12">
            <div className="w-full lg:w-1/2 flex flex-col justify-center ">
              <div className="space-y-4 sm:space-y-6">
                <div className="text-center sm:text-left">
                  <h1 className="text-4xl sm:text-6xl lg:text-7xl font-bold font-heading select-none tracking-tighter">
                    Earn <span className="text-lime-300">SPH</span>{" "}
                    <br className="sm:hidden block" />{" "}
                    <span className="text-lime-300">Tokens</span> for Simple
                    Actions
                  </h1>
                  <p className="mt-4 text-lg sm:text-xl text-gray-300 select-none">
                    Sign a message and receive 1 SPH testnet token instantly!
                    Boost your rewards by tweeting about us to earn 2 additional
                    SPH tokens, which will be verified on the campaign. You will
                    be placed in a queue to receive and will receive when
                    available.
                  </p>
                  <p className="text-base text-gray-400 select-none italic">
                    You don't need the "Early Adopter" NFT to participate in the
                    campaign!
                  </p>
                </div>
                <div
                  className={`flex flex-row sm:flex-row ${
                    isConnected && chain?.id === sphereone.id
                      ? "lg:flex-col"
                      : ""
                  } gap-4`}
                >
                  <ConnectButton.Custom>
                    {(props) => <CustomConnectButton {...(props as any)} />}
                  </ConnectButton.Custom>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex sm:flex-row gap-4 min-h-12">
                    <FaucetButton />
                    <TwitterButton />
                  </div>
                  {showMessage && (
                    <p className="text-base text-gray-400 select-none">
                      This may take a while...Don't worry, you are still getting
                      tokens!
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full text-center sm:text-left">
                <TransactionStateToast />
              </div>
            </div>

            <div className="w-full lg:w-1/2 flex justify-center lg:justify-end items-center align-middle">
              <div className="relative w-full max-w-[500px] aspect-square">
                <Image
                  src={SphereLogo}
                  alt="SphereLogo"
                  className="rounded-lg transform hover:scale-105 transition-transform duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
      <ToastContainer />
    </div>
  );
}
