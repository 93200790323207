import React, { useEffect } from "react";
import { ButtonMain } from "./ButtonMain";
import { useAccount } from "wagmi";
import { useFaucetContext } from "../contexts/FaucetContext";
import { QueueStatus } from "../utils/types";

export const FaucetButton: React.FC = () => {
  const { address, isConnected } = useAccount();
  const {
    transferTx,
    hasBalance,
    queueDocItem,
    checkQueueStatus,
    explorerUrl,
  } = useFaucetContext();

  useEffect(() => {
    if (isConnected && address) {
      checkQueueStatus();
    }
  }, [isConnected, address, checkQueueStatus]);

  const handleFaucetClick = async () => {
    await transferTx();
  };

  const isButtonEnabled =
    isConnected &&
    !hasBalance &&
    (queueDocItem?.status === QueueStatus.IDLE ||
      queueDocItem?.status === QueueStatus.FAILED);

  const getButtonClass = () => {
    if (!isButtonEnabled) {
      return "bg-gray-500 cursor-not-allowed";
    }
    if (queueDocItem?.status === QueueStatus.FAILED && !hasBalance) {
      return "bg-red-500 hover:bg-red-600";
    }
    return "bg-blue-500 hover:bg-blue-600";
  };

  const getButtonText = () => {
    if (!isConnected) {
      return "Connect Wallet";
    }
    if (queueDocItem?.status === QueueStatus.QUEUED) {
      return (
        <span className="items-center animate-pulse text-center">In queue</span>
      );
    }
    if (queueDocItem?.status === QueueStatus.PROCESSING) {
      return (
        <span className="flex items-center animate-pulse">
          Transfer in Progress
        </span>
      );
    }
    if (queueDocItem?.status === QueueStatus.COMPLETED || hasBalance) {
      return (
        <a
          href={`${explorerUrl}/address/${address}?tab=txs`}
          className="font-bold text-white hover:text-blue-200"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Explorer
        </a>
      );
    }
    if (queueDocItem?.status === QueueStatus.FAILED && !hasBalance) {
      return "Retry Transfer";
    }
    return <span className="font-bold">Get 1 SPH</span>;
  };

  return (
    <>
      {isConnected && (
        <ButtonMain
          onClick={handleFaucetClick}
          disabled={!isButtonEnabled}
          className={`px-4 py-2 sm:py-3 w-full rounded-md text-white transition-colors ${getButtonClass()} text-xs md:text-sm lg:text-base text-center`}
        >
          {getButtonText()}
        </ButtonMain>
      )}
    </>
  );
};
