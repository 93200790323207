import axios from "axios";
import { Web3 } from "web3";
import { sphereone as SPHERE_TESTNET_CHAIN_DATA } from "./wagmiConfig";
import { QueueDocItem, Transaction } from "./types";

export const SIGNABLE_MESSAGE = `
By signing this message you agree to be participating in the SphereOne Early Testers campaign.
You will receive 1 SPH Token.
`;

export const getWeb3Instance = () => {
  const chainRpc = SPHERE_TESTNET_CHAIN_DATA.rpcUrls.default.http[0];
  if (!chainRpc) {
    throw new Error("SPHERE_TESTNET_RPC is not set");
  }
  return new Web3(new Web3.providers.HttpProvider(chainRpc));
};

export const checkUserSPHBalance = async (
  userAddress: string
): Promise<number> => {
  const web3 = getWeb3Instance();
  const balance = await web3.eth.getBalance(userAddress);
  return parseFloat(web3.utils.fromWei(balance, "ether"));
};

export const buildTransferTransaction = async (
  senderAddress: string,
  recipientAddress: string,
  amount: string
): Promise<Transaction> => {
  const web3 = getWeb3Instance();
  const gasPrice = await web3.eth.getGasPrice();

  const tx: Transaction = {
    from: senderAddress,
    to: recipientAddress,
    value: amount,
    data: "0x",
    gas: 21000,
    gasPrice: gasPrice,
    chainId: SPHERE_TESTNET_CHAIN_DATA.id,
  };

  return tx;
};

export const addToFaucetQueue = async (
  address: string,
  transferTx: Transaction,
  isXfaucet: boolean
) => {
  try {
    const formattedTransferTx = {
      ...transferTx,
      gasPrice: transferTx.gasPrice?.toString(),
    };

    await axios.post(`${process.env.REACT_APP_API_URL}/add`, {
      address,
      transferTx: formattedTransferTx,
      isXfaucet,
    });
  } catch (error) {
    console.error("Error adding to faucet queue:", error);
    throw error;
  }
};

export const checkUserQueueStatus = async (
  address: string
): Promise<{ faucetStatus: QueueDocItem; XfaucetStatus: QueueDocItem }> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/status/${address}`
    );
    return response.data;
  } catch (error) {
    console.error("Error checking user queue status:", error);
    throw error;
  }
};
