export enum QueueStatus {
  IDLE = "idle",
  QUEUED = "queued",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface Transaction {
  from?: string;
  to?: string;
  data?: string;
  nonce?: bigint;
  gasPrice?: bigint;
  gas?: number;
  value?: string;
  chainId?: number;
}

export interface QueueDocItem {
  address: string;
  completedAt?: Date | string;
  createdAt?: Date | string;
  transferTx: Transaction;
  transferTxHash?: string;
  txHash?: string;
  status: QueueStatus;
}
