import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

type CustomConnectButtonProps = Parameters<
  typeof ConnectButton.Custom
>[0]["children"];

const CustomConnectButton: React.FC<ReturnType<CustomConnectButtonProps>> = ({
  account,
  chain,
  openAccountModal,
  openChainModal,
  openConnectModal,
  authenticationStatus,
  mounted,
}: any) => {
  const ready = mounted && authenticationStatus !== "loading";
  const connected =
    ready &&
    account &&
    chain &&
    (!authenticationStatus || authenticationStatus === "authenticated");

  return (
    <div
      className="flex flex-row w-full text-center justify-center space-x-3"
      {...(!ready && {
        "aria-hidden": true,
        style: {
          opacity: 0,
          pointerEvents: "none",
          userSelect: "none",
        },
      })}
    >
      {(() => {
        if (!connected) {
          return (
            <button
              onClick={openConnectModal}
              type="button"
              className="w-full px-6 py-2 sm:px-8 sm:py-3 rounded-md text-blue-400 border border-blue-400 hover:bg-blue-400 hover:text-white transition-colors"
            >
              <span className="font-bold">Connect Wallet</span>
            </button>
          );
        }

        if (chain?.unsupported) {
          return (
            <button
              onClick={openChainModal}
              type="button"
              className="w-full px-6 py-2 sm:px-8 sm:py-3 rounded-md bg-red-500 text-white hover:bg-red-600 transition-colors"
            >
              <span className="text-xs md:text-sm lg:text-base">
                Wrong network
              </span>
            </button>
          );
        }

        return (
          <div className="flex flex-row w-full gap-3">
            <button
              onClick={openChainModal}
              style={{ display: "flex", alignItems: "center", gap: 8 }}
              type="button"
              className="w-full px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors"
            >
              {chain.hasIcon && (
                <div
                  style={{
                    background: "0,0,0,0",
                    borderRadius: 100,
                    overflow: "hidden",
                    marginRight: 4,
                  }}
                >
                  {chain.iconUrl && (
                    <img
                      alt={chain.name ?? "Chain icon"}
                      src={chain.iconUrl}
                      className="sm:w-8 w-6"
                    />
                  )}
                </div>
              )}
              <span className="text-xs md:text-sm lg:text-base">
                {chain.name}
              </span>
            </button>

            <button
              onClick={openAccountModal}
              type="button"
              className="w-full px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 transition-colors text-xs md:text-sm lg:text-base"
            >
              {account.displayName}
              {account.displayBalance ? ` (${account.displayBalance})` : ""}
            </button>
          </div>
        );
      })()}
    </div>
  );
};

export default CustomConnectButton;
