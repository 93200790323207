import React, { useEffect, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useFaucetContext } from "../contexts/FaucetContext";
import { QueueStatus } from "../utils/types";

export const TransactionStateToast: React.FC = () => {
  const {
    error,
    explorerUrl,
    checkQueueStatus,
    hasBalance,
    queueDocItem,
    twitterQueueDocItem,
  } = useFaucetContext();
  const { address, isConnected } = useAccount();
  const [queueToastShown, setQueueToastShown] = useState(false);
  const [twitterToastShown, setTwitterToastShown] = useState(false);

  const checkStatus = useCallback(async () => {
    if (
      queueDocItem?.status === QueueStatus.QUEUED ||
      queueDocItem?.status === QueueStatus.PROCESSING ||
      twitterQueueDocItem?.status === QueueStatus.QUEUED ||
      twitterQueueDocItem?.status === QueueStatus.PROCESSING
    ) {
      await checkQueueStatus();
    }
  }, [queueDocItem?.status, checkQueueStatus, twitterQueueDocItem?.status]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (!isConnected || !address) return;

    if (
      (queueDocItem?.status === QueueStatus.QUEUED ||
        queueDocItem?.status === QueueStatus.PROCESSING) &&
      !queueToastShown
    ) {
      toast.info("Checking transaction status...", { autoClose: 60000 });
      setQueueToastShown(true);
    }

    if (
      queueDocItem?.status === QueueStatus.COMPLETED &&
      !hasBalance &&
      !queueToastShown
    ) {
      const transferTxHash = `${explorerUrl}/tx/${
        queueDocItem?.transferTxHash || queueDocItem?.txHash
      }`;
      setQueueToastShown(true);
      toast.success(
        <a href={transferTxHash} target="_blank" rel="noopener noreferrer">
          <p>Transaction Successful! View on Explorer</p>
        </a>,
        { autoClose: 5000 }
      );
    }

    if (queueDocItem?.status === QueueStatus.FAILED) {
      toast.error(`Transaction failed. Please try again.`, {
        autoClose: false,
      });
      setQueueToastShown(false);
    }
  }, [
    queueDocItem?.status,
    checkStatus,
    explorerUrl,
    isConnected,
    queueDocItem?.transferTxHash,
    queueDocItem?.txHash,
    hasBalance,
    address,
    queueToastShown,
  ]);

  useEffect(() => {
    if (!isConnected || !address) return;

    if (
      (twitterQueueDocItem?.status === QueueStatus.QUEUED ||
        twitterQueueDocItem?.status === QueueStatus.PROCESSING) &&
      !twitterToastShown
    ) {
      toast.info("Checking Twitter transaction status...", {
        autoClose: 60000,
      });
      setTwitterToastShown(true);
    }

    if (
      twitterQueueDocItem?.status === QueueStatus.COMPLETED &&
      !hasBalance &&
      !twitterToastShown
    ) {
      const transferTxHash = `${explorerUrl}/tx/${twitterQueueDocItem?.transferTxHash}`;
      setTwitterToastShown(true);
      toast.success(
        <a href={transferTxHash} target="_blank" rel="noopener noreferrer">
          <p>Twitter Transaction Successful! View on Explorer</p>
        </a>,
        { autoClose: 5000 }
      );
    }

    if (twitterQueueDocItem?.status === QueueStatus.FAILED) {
      toast.error(`Twitter transaction failed. Please try again.`, {
        autoClose: false,
      });
      setTwitterToastShown(false);
    }
  }, [
    twitterQueueDocItem?.status,
    checkStatus,
    explorerUrl,
    isConnected,
    twitterQueueDocItem?.transferTxHash,
    hasBalance,
    address,
    twitterToastShown,
  ]);

  useEffect(() => {
    if (
      queueDocItem?.status === QueueStatus.FAILED ||
      twitterQueueDocItem?.status === QueueStatus.FAILED
    ) {
      setQueueToastShown(false);
      setTwitterToastShown(false);
    }
  }, [queueDocItem?.status, twitterQueueDocItem?.status]);

  return null;
};
