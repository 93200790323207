import React from "react";
import { Image } from "./Images";
import logo from "../assets/logo-white.svg";

const Header = () => {
  return (
    <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
      <header>
        <a
          href="https://sphereone.xyz/"
          target="_blank"
          rel="noopener noreferrer"
          className="h-14 flex w-fit items-center"
        >
          <Image
            src={logo}
            alt="SphereOne Logo"
            className="h-6 w-6 text-blue-400"
          />
          <span className="ml-2 text-xl font-bold font-heading">SphereOne</span>
        </a>
      </header>
    </div>
  );
};

export default Header;
