import React, { useEffect } from "react";
import { ButtonMain } from "./ButtonMain";
import { useAccount } from "wagmi";
import { useFaucetContext } from "../contexts/FaucetContext";
import { XIcon } from "../assets/flowbite-icons";
import { QueueStatus } from "../utils/types";

export const TwitterButton: React.FC = () => {
  const { address, isConnected } = useAccount();
  const {
    XtransferTx,
    hasBalance,
    twitterQueueDocItem,
    checkQueueStatus,
    explorerUrl,
  } = useFaucetContext();

  useEffect(() => {
    if (isConnected && address) {
      checkQueueStatus();
    }
  }, [isConnected, address, checkQueueStatus]);

  const handleXClick = async () => {
    const tweetText = `I'm getting SPH Tokens from the Action Agent! Check it out here! #SphereOne ${process.env.REACT_APP_WEBPAGE_URL}\n\nSphereOne simplifies blockchain interactions by using AI agents to automate and optimize transactions across multiple networks, making Web3 accessible and efficient for everyone`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}`;
    window.open(twitterUrl, "_blank");
    await XtransferTx();
  };

  const isButtonEnabled =
    isConnected &&
    !hasBalance &&
    (twitterQueueDocItem?.status === QueueStatus.IDLE ||
      twitterQueueDocItem?.status === QueueStatus.FAILED);

  const getButtonClass = () => {
    if (!isButtonEnabled) {
      return "bg-gray-500 cursor-not-allowed";
    }
    if (twitterQueueDocItem?.status === QueueStatus.FAILED && !hasBalance) {
      return "bg-red-500 hover:bg-red-600";
    }
    return "bg-blue-500 hover:bg-blue-600";
  };

  const getButtonText = () => {
    if (!isConnected) {
      return "Connect Wallet";
    }
    if (twitterQueueDocItem?.status === QueueStatus.QUEUED) {
      return (
        <span className="items-center animate-pulse text-center">In queue</span>
      );
    }
    if (twitterQueueDocItem?.status === QueueStatus.PROCESSING) {
      return (
        <span className="flex items-center animate-pulse">
          Transfer in Progress
        </span>
      );
    }
    if (twitterQueueDocItem?.status === QueueStatus.COMPLETED || hasBalance) {
      return (
        <a
          href={`${explorerUrl}/address/${address}?tab=txs`}
          className="font-bold text-white hover:text-blue-200"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Explorer
        </a>
      );
    }
    if (twitterQueueDocItem?.status === QueueStatus.FAILED && !hasBalance) {
      return "Retry Transfer";
    }
    return (
      <div className="flex flex-col justify-center text-center align-middle items-center">
        <p className="flex text-center align-middle items-center">
          Share on <XIcon />
        </p>

        <span className="text-center align-middle items-center">
          for <span className="font-bold text-lime-300">2 SPH</span>
        </span>
      </div>
    );
  };

  return (
    <>
      {isConnected && (
        <ButtonMain
          onClick={handleXClick}
          disabled={!isButtonEnabled}
          className={`px-4 py-2 sm:py-3 w-full rounded-md text-white transition-colors ${getButtonClass()} text-xs md:text-sm lg:text-base text-center`}
        >
          {getButtonText()}
        </ButtonMain>
      )}
    </>
  );
};
