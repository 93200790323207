import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useAccount, useSignMessage } from "wagmi";
import { Bytes } from "web3";
import {
  checkUserSPHBalance,
  SIGNABLE_MESSAGE,
  buildTransferTransaction,
  addToFaucetQueue,
  checkUserQueueStatus,
} from "../utils/blockchainUtils";
import { sphereone } from "../utils/wagmiConfig";
import { QueueDocItem, QueueStatus } from "../utils/types";

interface FaucetContextType {
  isLoading: boolean;
  error: string | null;
  txHash: Bytes | null;
  XtxHash: Bytes | null;
  hasBalance: boolean;
  transferTx: () => Promise<void>;
  XtransferTx: () => Promise<void>;
  resetState: () => void;
  explorerUrl: string;
  queueDocItem: QueueDocItem | null;
  twitterQueueDocItem: QueueDocItem | null;
  checkQueueStatus: () => Promise<void>;
}

const FaucetContext = createContext<FaucetContextType | undefined>(undefined);

const docItemInitialState = {
  address: "",
  mintTx: {
    from: "",
    to: "",
    data: "",
    nonce: BigInt(0),
    gasPrice: BigInt(0),
    gas: 0,
  },
  transferTx: {
    from: "",
    to: "",
    data: "",
    nonce: BigInt(0),
    gasPrice: BigInt(0),
    gas: 0,
  },
  mintTxHash: "",
  transferTxHash: "",
  status: QueueStatus.IDLE,
  createdAt: new Date(),
};

export const FaucetProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const explorerUrl = sphereone.blockExplorers.default.url;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [txHash, setTxHash] = useState<Bytes | null>(null);
  const [twitterTxHash, setTwitterTxHash] = useState<Bytes | null>(null);
  const [hasBalance, setHasBalance] = useState(false);

  const [queueDocItem, setQueueDocItem] = useState<QueueDocItem | null>(
    docItemInitialState
  );

  const [twitterQueueDocItem, setTwitterQueueDocItem] =
    useState<QueueDocItem | null>(docItemInitialState);

  const resetState = useCallback(() => {
    setIsLoading(false);
    setError(null);
    setTxHash(null);
    setTwitterTxHash(null);
    setHasBalance(false);
  }, []);

  const checkBalance = useCallback(async () => {
    if (!address) return;

    try {
      const userBalance = await checkUserSPHBalance(address);
      setHasBalance(userBalance > 3);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    }
  }, [address]);

  const checkQueueStatus = useCallback(async () => {
    if (!address) return;
    try {
      const queueStatus = await checkUserQueueStatus(address);

      if (queueStatus?.faucetStatus) {
        setQueueDocItem(queueStatus.faucetStatus);
      } else {
        setQueueDocItem(docItemInitialState);
      }

      if (queueStatus?.XfaucetStatus) {
        setTwitterQueueDocItem(queueStatus.XfaucetStatus);
      } else {
        setTwitterQueueDocItem(docItemInitialState);
      }
    } catch (error) {
      console.error("Error in checkQueueStatus provider:", error);
      setError(error instanceof Error ? error.message : String(error));
    }
  }, [address, setQueueDocItem, setTwitterQueueDocItem]);

  useEffect(() => {
    if (isConnected && address) {
      checkBalance();
    } else {
      resetState();
    }
  }, [isConnected, address, checkBalance, resetState]);

  const transferTx = useCallback(async () => {
    if (!address) {
      setError("Wallet not connected");
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setTxHash(null);

    try {
      const userBalance = await checkUserSPHBalance(address);
      if (userBalance > 3) {
        setHasBalance(true);
        setIsLoading(false);
        return;
      }

      try {
        await signMessageAsync({ message: SIGNABLE_MESSAGE });
      } catch (signError) {
        throw new Error("User rejected the signature request");
      }

      const transferTx = await buildTransferTransaction(
        "0x6f18e1aaf9fD0231265A17C19643a316Ca6E740d", // Faucet Sender Address
        address, // To address
        "1000000000000000000" // 1 SPH Token
      );

      await addToFaucetQueue(address, transferTx, false);

      const updatedQueueDocItem = await checkUserQueueStatus(address);

      setQueueDocItem(updatedQueueDocItem.faucetStatus);
    } catch (err) {
      console.error("Error in faucetTx provider:", err);
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [address, isLoading, signMessageAsync]); 

  const XtransferTx = useCallback(async () => {
    if (!address) {
      setError("Wallet not connected");
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setTwitterTxHash(null);

    try {
      const userBalance = await checkUserSPHBalance(address);
      if (userBalance > 3) {
        setHasBalance(true);
        setIsLoading(false);
        return;
      }

      const XtransferTx = await buildTransferTransaction(
        "0x0AD1189A1422129CC6ab3D4e20a15Ef772271Cf4", // XFaucet Sender Address
        address, // toAddress
        "2000000000000000000" // 2 SPH Token
      );

      await addToFaucetQueue(address, XtransferTx, true);
      const updatedTwitterQueueDocItem = await checkUserQueueStatus(address);

      setTwitterQueueDocItem(updatedTwitterQueueDocItem.XfaucetStatus);
    } catch (err) {
      console.error("Error in faucetTx provider:", err);
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [address, isLoading]);

  return (
    <FaucetContext.Provider
      value={{
        isLoading,
        error,
        txHash,
        XtxHash: twitterTxHash,
        hasBalance,
        transferTx,
        XtransferTx,
        resetState,
        explorerUrl,
        queueDocItem,
        twitterQueueDocItem,
        checkQueueStatus,
      }}
    >
      {children}
    </FaucetContext.Provider>
  );
};

export const useFaucetContext = () => {
  const context = useContext(FaucetContext);
  if (context === undefined) {
    throw new Error("useFaucetContext must be used within a FaucetContext");
  }
  return context;
};
