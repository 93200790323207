import React from "react";

const Footer = () => {
  return (
    <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">
      <footer className="py-4 sm:py-6 text-center text-sm text-gray-400">
        <p>&copy; 2024 SphereOne. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;
