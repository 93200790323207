import { Chain, getDefaultConfig } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";

export const sphereone = {
  id: 14855,
  name: "SPHEREONE TESTNET",
  iconUrl:
    "https://firebasestorage.googleapis.com/v0/b/sphereone-testing.appspot.com/o/images%2FagentsLogo%2FSuperAgentLogo.png?alt=media&token=f1d7b872-741b-47f8-aad7-c881f41a9422",
  iconBackground: "#fff",
  nativeCurrency: { name: "SphereToken", symbol: "SPH", decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        "https://rpc-sphereone-tdwodktc3s.t.conduit.xyz/LsxRJyF4xUwzZ9VooEEYCVjxm3skb62Nh",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "conduit",
      url: "https://explorer-sphereone-tdwodktc3s.t.conduit.xyz",
    },
  },
} as const satisfies Chain;

export const config = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
  chains: [sphereone],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet],
    },
  ],
});
