import React, { ButtonHTMLAttributes, ReactNode } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: "outline" | "solid";
  className?: string;
}

export const ButtonMain: React.FC<ButtonProps> = ({
  children,
  variant = "solid",
  className = "",
  ...props
}) => (
  <button
    className={`${className} ${variant === "outline" ? "border" : ""}`}
    {...props}
  >
    {children}
  </button>
);
